import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const { domainData } = useAuthContext();
  const settings = useSettingsContext();

  // eslint-disable-next-line consistent-return
  const domainLogo = useMemo(() => {
    if (domainData) {
      if (settings?.themeMode === 'light') {
        return domainData?.white_logo;
      }
      return domainData?.dark_logo;
    }
  }, [domainData, settings]);

  const logo = (
    <Box
      component="img"
      src={domainLogo ?? ''}
      sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return domainLogo ? (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  ) : null;
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
